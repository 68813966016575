import EventEmitter from 'eventemitter3'
import executeTree from './executeTree'
import createStaticTree from './staticTree'
import resolveProvider from './providers/Resolve'
import Path from './Path'
import Provider from './Provider'
import { Primitive } from './primitives'
import { FunctionTreeExecutionError } from './errors'
import { isPromise } from './utils'

/*
  Need to create a unique ID for each execution to identify it
  in debugger
*/
function createUniqueId() {
  return Date.now() + '_' + Math.floor(Math.random() * 10000)
}

/*
  Validate any returned value from a function. Has
  to be nothing or an object
*/
function isValidResult(result) {
  return !result || (typeof result === 'object' && !Array.isArray(result))
}

/*
  Create an error with execution details
*/
function createErrorObject(error, execution, functionDetails, payload) {
  const errorToReturn = error

  errorToReturn.execution = execution
  errorToReturn.functionDetails = functionDetails
  errorToReturn.payload = Object.assign({}, payload, {
    _execution: {
      id: execution.id,
      functionIndex: functionDetails.functionIndex,
    },
    error: error.toJSON
      ? error.toJSON()
      : {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
  })

  return errorToReturn
}

class FunctionTreeExecution {
  constructor(name, staticTree, functionTree, errorCallback) {
    this.id = createUniqueId()
    this.name = name || staticTree.name || this.id
    this.staticTree = staticTree
    this.functionTree = functionTree
    this.datetime = Date.now()
    this.errorCallback = errorCallback
    this.hasThrown = false
    this.isAsync = false

    this.runFunction = this.runFunction.bind(this)
  }

  /*
    Creates the context for the current function to be run,
    emits events and handles its returned value. Also handles
    the returned value being a promise
  */
  runFunction(funcDetails, payload, prevPayload, next) {
    if (this.hasThrown) {
      return
    }

    const context = this.createContext(funcDetails, payload, prevPayload)
    const functionTree = this.functionTree
    const errorCallback = this.errorCallback
    const execution = this
    let result

    functionTree.emit('functionStart', execution, funcDetails, payload)
    try {
      result = funcDetails.function(context)
    } catch (error) {
      this.hasThrown = true

      return errorCallback(
        createErrorObject(error, execution, funcDetails, payload),
        execution,
        funcDetails,
        payload
      )
    }

    /*
      If result is a promise we want to emit an event and wait for it to resolve to
      move on
    */
    if (isPromise(result)) {
      functionTree.emit(
        'asyncFunction',
        execution,
        funcDetails,
        payload,
        result
      )
      this.isAsync = true
      result
        .then(function(result) {
          if (result instanceof Path) {
            functionTree.emit(
              'functionEnd',
              execution,
              funcDetails,
              payload,
              result
            )
            next(result.toJSON())
          } else if (funcDetails.outputs) {
            functionTree.emit(
              'functionEnd',
              execution,
              funcDetails,
              payload,
              result
            )
            throw new FunctionTreeExecutionError(
              execution,
              funcDetails,
              payload,
              new Error(
                'The result ' +
                  JSON.stringify(result) +
                  ' from function ' +
                  funcDetails.name +
                  ' needs to be a path of either ' +
                  Object.keys(funcDetails.outputs)
              )
            )
          } else if (isValidResult(result)) {
            functionTree.emit(
              'functionEnd',
              execution,
              funcDetails,
              payload,
              result
            )
            next({
              payload: result,
            })
          } else {
            functionTree.emit(
              'functionEnd',
              execution,
              funcDetails,
              payload,
              result
            )
            throw new FunctionTreeExecutionError(
              execution,
              funcDetails,
              payload,
              new Error(
                'The result ' +
                  JSON.stringify(result) +
                  ' from function ' +
                  funcDetails.name +
                  ' is not a valid result'
              )
            )
          }
        })
        .catch(function(result) {
          if (execution.hasThrown) {
            return
          }

          if (result instanceof Error) {
            execution.hasThrown = true
            errorCallback(
              createErrorObject(result, execution, funcDetails, payload),
              execution,
              funcDetails,
              payload
            )
          } else if (result instanceof Path) {
            functionTree.emit(
              'functionEnd',
              execution,
              funcDetails,
              payload,
              result
            )
            next(result.toJSON())
          } else if (funcDetails.outputs) {
            let error = new FunctionTreeExecutionError(
              execution,
              funcDetails,
              payload,
              new Error(
                'The result ' +
                  JSON.stringify(result) +
                  ' from function ' +
                  funcDetails.name +
                  ' needs to be a path of either ' +
                  Object.keys(funcDetails.outputs)
              )
            )

            execution.hasThrown = true
            errorCallback(
              createErrorObject(error, execution, funcDetails, payload),
              execution,
              funcDetails,
              payload
            )
          } else if (isValidResult(result)) {
            functionTree.emit(
              'functionEnd',
              execution,
              funcDetails,
              payload,
              result
            )
            next({
              payload: result,
            })
          } else {
            let error = new FunctionTreeExecutionError(
              execution,
              funcDetails,
              payload,
              new Error(
                'The result ' +
                  JSON.stringify(result) +
                  ' from function ' +
                  funcDetails.name +
                  ' is not a valid result'
              )
            )
            execution.hasThrown = true

            errorCallback(
              createErrorObject(error, execution, funcDetails, payload),
              execution,
              funcDetails,
              payload
            )
          }
        })
    } else if (result instanceof Path) {
      functionTree.emit('functionEnd', execution, funcDetails, payload, result)
      next(result.toJSON())
    } else if (funcDetails.outputs) {
      let error = new FunctionTreeExecutionError(
        execution,
        funcDetails,
        payload,
        new Error(
          'The result ' +
            JSON.stringify(result) +
            ' from function ' +
            funcDetails.name +
            ' needs to be a path of either ' +
            Object.keys(funcDetails.outputs)
        )
      )

      this.hasThrown = true
      errorCallback(
        createErrorObject(error, execution, funcDetails, payload),
        execution,
        funcDetails,
        payload
      )
    } else if (isValidResult(result)) {
      functionTree.emit('functionEnd', execution, funcDetails, payload, result)
      next({
        payload: result,
      })
    } else {
      let error = new FunctionTreeExecutionError(
        execution,
        funcDetails,
        payload,
        new Error(
          'The result ' +
            JSON.stringify(result) +
            ' from function ' +
            funcDetails.name +
            ' is not a valid result'
        )
      )
      this.hasThrown = true

      errorCallback(
        createErrorObject(error, execution, funcDetails, payload),
        execution,
        funcDetails,
        payload
      )
    }
  }

  /*
    Creates the context for the next running function
  */
  createContext(functionDetails, payload, prevPayload) {
    const contextProviders = this.functionTree.contextProviders
    const newContext = {
      execution: this,
      props: payload || {},
      functionDetails,
      path: functionDetails.outputs
        ? Object.keys(functionDetails.outputs).reduce((output, outputPath) => {
            output[outputPath] = (payload) => new Path(outputPath, payload)

            return output
          }, {})
        : null,
    }

    const debuggerProvider =
      contextProviders.debugger &&
      contextProviders.debugger.get(
        newContext,
        functionDetails,
        payload,
        prevPayload
      )

    const context = Object.keys(contextProviders).reduce(
      (currentContext, name) => {
        const provider = contextProviders[name]

        if (provider instanceof Provider) {
          currentContext[name] = provider.get(
            currentContext,
            functionDetails,
            payload,
            prevPayload
          )
        } else {
          currentContext[name] = provider
        }

        return currentContext
      },
      newContext
    )

    if (debuggerProvider) {
      return Object.keys(context).reduce((currentContext, name) => {
        const provider = contextProviders[name]

        if (provider && provider instanceof Provider && provider.wrap) {
          currentContext[name] =
            typeof provider.wrap === 'function'
              ? provider.wrap(context, functionDetails)
              : provider.getWrapped(name, context)
        } else {
          currentContext[name] = context[name]
        }

        return currentContext
      }, {})
    }

    return context
  }
}

export class FunctionTree extends EventEmitter {
  constructor(contextProviders = {}, options = {}) {
    super()
    this.cachedTrees = []
    this.cachedStaticTrees = []
    this.executeBranchWrapper =
      options.executeBranchWrapper ||
      function(cb) {
        cb()
      }

    if (
      typeof contextProviders !== 'object' ||
      contextProviders === null ||
      Array.isArray(contextProviders)
    ) {
      throw new Error(
        'You have to pass an object of context providers to FunctionTree'
      )
    }

    const providerKeys = Object.keys(contextProviders)

    if (
      providerKeys.indexOf('props') >= 0 ||
      providerKeys.indexOf('path') >= 0 ||
      providerKeys.indexOf('resolve') >= 0 ||
      providerKeys.indexOf('execution') >= 0 ||
      providerKeys.indexOf('debugger') >= 0
    ) {
      throw new Error(
        'You are trying to add a provider with protected key. "props", "path", "resolve", "execution" and "debugger" are protected'
      )
    }

    this.contextProviders = Object.assign({}, contextProviders, {
      resolve: resolveProvider,
    })

    this.run = this.run.bind(this)
  }

  /*
    Analyses the tree to identify paths and its validity. This analysis
    is cached. Then the method creates an execution for the tree to run.
  */
  run() {
    let name
    let tree
    let payload
    let cb
    let staticTree
    const args = [].slice.call(arguments)
    args.forEach((arg) => {
      if (typeof arg === 'string') {
        name = arg
      } else if (Array.isArray(arg) || arg instanceof Primitive) {
        tree = arg
      } else if (!tree && typeof arg === 'function') {
        tree = arg
      } else if (typeof arg === 'function') {
        cb = arg
      } else {
        payload = arg
      }
    })

    if (!tree) {
      throw new Error('function-tree - You did not pass in a function tree')
    }

    const withResolveAndReject = (resolve, reject) => {
      const treeIdx = this.cachedTrees.indexOf(tree)
      if (treeIdx === -1) {
        staticTree = createStaticTree(name, tree)
        this.cachedTrees.push(tree)
        this.cachedStaticTrees.push(staticTree)
      } else {
        staticTree = this.cachedStaticTrees[treeIdx]
      }
      const execution = new FunctionTreeExecution(
        name,
        staticTree,
        this,
        (error, execution, funcDetails, finalPayload) => {
          this.emit('error', error, execution, funcDetails, finalPayload)
          reject(error)
        }
      )

      this.emit('start', execution, payload)
      executeTree(
        execution,
        payload,
        this.executeBranchWrapper,
        (funcDetails, path, currentPayload) => {
          this.emit('pathStart', path, execution, funcDetails, currentPayload)
        },
        (currentPayload) => {
          this.emit('pathEnd', execution, currentPayload)
        },
        (currentPayload, functionsToResolve) => {
          this.emit(
            'parallelStart',
            execution,
            currentPayload,
            functionsToResolve
          )
        },
        (currentPayload, functionsResolved) => {
          this.emit(
            'parallelProgress',
            execution,
            currentPayload,
            functionsResolved
          )
        },
        (currentPayload, functionsResolved) => {
          this.emit('parallelEnd', execution, currentPayload, functionsResolved)
        },
        (finalPayload) => {
          this.emit('end', execution, finalPayload)
          resolve === reject
            ? resolve(null, finalPayload)
            : resolve(finalPayload)
        }
      )
    }

    if (cb) {
      withResolveAndReject(cb, cb)
    } else {
      return new Promise(withResolveAndReject)
    }
  }
}
