import FunctionTree from 'function-tree'
import Module from './Module'
import {
  ensurePath,
  isDeveloping,
  throwError,
  isSerializable,
  forceSerializable,
  isObject,
  getProviders,
  getModule,
  cleanPath,
} from './utils'
import DebuggerProvider from './providers/Debugger'
import ModuleProvider from './providers/Module'

/*
  The controller is where everything is attached. The devtools
  is attached directly. Also a top level module is created.
  The controller creates the function tree that will run all signals,
  based on top level providers and providers defined in modules
*/
class BaseController extends FunctionTree {
  constructor(rootModule, options, functionTreeOptions) {
    super({}, functionTreeOptions)
    const {
      Model,
      devtools = null,
      stateChanges = typeof window !== 'undefined' && window.CEREBRAL_STATE,
      throwToConsole = true,
      preventInitialize = false,
    } = options
    const getSignal = this.getSignal
    const getSignals = this.getSignals

    this.getSignal = () => {
      throwError(
        'You are grabbing a signal before controller has initialized, please wait for "initialized" event'
      )
    }

    this.getSignals = () => {
      throwError(
        'You are grabbing a signals before controller has initialized, please wait for "initialized" event'
      )
    }

    if (!(rootModule instanceof Module)) {
      throwError(
        'You did not pass a root module to the controller. The first argument has to be a module'
      )
    }

    this.throwToConsole = throwToConsole

    this.devtools = devtools
    this.module = rootModule.create(this, [])
    this.model = new Model(this)

    if (!preventInitialize) {
      this.emit('initialized:model')
    }

    this.contextProviders = Object.assign(
      this.contextProviders,
      getProviders(this.module),
      {
        controller: this,
        state: this.model.StateProvider(this.devtools),
        module: ModuleProvider(this.devtools),
      },
      this.devtools
        ? {
            debugger: DebuggerProvider(this.devtools),
          }
        : {}
    )

    if (stateChanges) {
      Object.keys(stateChanges).forEach((statePath) => {
        this.model.set(ensurePath(statePath), stateChanges[statePath])
      })
    }

    if (this.devtools) {
      this.devtools.init(this)
    }

    if (
      !this.devtools &&
      isDeveloping() &&
      typeof navigator !== 'undefined' &&
      /Chrome/.test(navigator.userAgent)
    ) {
      console.warn(
        'You are not using the Cerebral devtools. It is highly recommended to use it in combination with the debugger: https://cerebraljs.com/docs/introduction/debugger.html'
      )
    }

    if (isDeveloping()) {
      this.on('functionStart', (execution, functionDetails, payload) => {
        try {
          JSON.stringify(payload)
        } catch (e) {
          throwError(
            `The function ${functionDetails.name} in signal ${
              execution.name
            } is not given a valid payload`
          )
        }
      })
      this.on(
        'functionEnd',
        (execution, functionDetails, payload, propsToAdd) => {
          if (devtools && devtools.preventPropsReplacement) {
            Object.keys(propsToAdd || {}).forEach((key) => {
              if (key in payload) {
                throw new Error(
                  `Cerebral Devtools - You have activated the "preventPropsReplacement" option and in signal "${
                    execution.name
                  }", before the action "${
                    functionDetails.name
                  }", the key "${key}" was replaced`
                )
              }
            })
          }
        }
      )
    }

    this.getSignal = getSignal
    this.getSignals = getSignals

    if (!preventInitialize) {
      this.emit('initialized')
    }
  }
  /*
    Conveniance method for grabbing the model
  */
  getModel() {
    return this.model
  }
  /*
    Method called by view to grab state
  */
  getState(path) {
    return this.model.get(ensurePath(cleanPath(path)))
  }
  /*
    Uses function tree to run the array and optional
    payload passed in. The payload will be checkd
  */
  runSignal(name, signal, payload = {}) {
    if (this.devtools && (!isObject(payload) || !isSerializable(payload))) {
      console.warn(
        `You passed an invalid payload to signal "${name}". Only serializable payloads can be passed to a signal. The payload has been ignored. This is the object:`,
        payload
      )
      payload = {}
    }

    if (this.devtools) {
      payload = Object.keys(payload).reduce((currentPayload, key) => {
        if (!isSerializable(payload[key], this.devtools.allowedTypes)) {
          console.warn(
            `You passed an invalid payload to signal "${name}", on key "${key}". Only serializable values like Object, Array, String, Number and Boolean can be passed in. Also these special value types:`,
            this.devtools.allowedTypes
          )

          return currentPayload
        }

        currentPayload[key] = forceSerializable(payload[key])

        return currentPayload
      }, {})
    }

    this.run(name, signal, payload, (error) => {
      if (error) {
        const signalPath = ensurePath(error.execution.name)
        const catchingResult = signalPath.reduce(
          (details, key, index) => {
            if (details.currentModule.catch) {
              details.catchingModule = details.currentModule
            }

            details.currentModule = details.currentModule.modules[key]

            return details
          },
          {
            currentModule: this.module,
            catchingModule: null,
          }
        )

        if (catchingResult.catchingModule) {
          for (let [errorType, signalChain] of catchingResult.catchingModule
            .catch) {
            if (error instanceof errorType) {
              this.runSignal('catch', signalChain, error.payload)

              // Throw the error to console even if handling it
              if (this.throwToConsole) {
                setTimeout(() => {
                  console.log(
                    `Cerebral is handling error "${error.name}: ${
                      error.message
                    }" thrown by signal "${
                      error.execution.name
                    }". Check debugger for more information.`
                  )
                })
              }

              return
            }
          }
        }

        if (error.execution.isAsync) {
          setTimeout(() => {
            throw error
          })
        } else {
          throw error
        }
      }
    })
  }
  /*
    Returns a function which binds the name/path of signal,
    and the array. This allows view layer to just call it with
    an optional payload and it will run
  */
  getSignal(path) {
    const pathArray = ensurePath(path)
    const signalKey = pathArray.pop()
    const module = pathArray.reduce((currentModule, key) => {
      return currentModule ? currentModule.modules[key] : undefined
    }, this.module)
    const signal = module && module.signals[signalKey]

    if (!signal) {
      throwError(
        `The signal on path "${path}" does not exist, please check path`
      )
    }

    return signal && signal.run
  }
  getSignals(modulePath) {
    const pathArray = ensurePath(modulePath)
    const module = pathArray.reduce((currentModule, key) => {
      return currentModule ? currentModule.modules[key] : undefined
    }, this.module)

    const signals = module && module.signals

    if (!signals) {
      return undefined
    }

    const callableSignals = {}
    for (const name in signals) {
      const signal = signals[name].run
      callableSignals[name] = signal
    }

    return callableSignals
  }
  addModule(path, module) {
    const pathArray = ensurePath(path)
    const moduleKey = pathArray.pop()
    const parentModule = getModule(pathArray, this.module)
    const newModule = module.create(this, ensurePath(path))
    parentModule.modules[moduleKey] = newModule

    if (newModule.providers) {
      Object.assign(this.contextProviders, newModule.providers)
    }

    this.emit('moduleAdded', path.split('.'), newModule)

    this.flush()
  }
  removeModule(path) {
    if (!path) {
      console.warn('Controller.removeModule requires a Module Path')
      return null
    }

    const pathArray = ensurePath(path)
    const moduleKey = pathArray.pop()
    const parentModule = getModule(pathArray, this.module)

    const module = parentModule.modules[moduleKey]

    if (module.providers) {
      Object.keys(module.providers).forEach((provider) => {
        delete this.contextProviders[provider]
      })
    }

    delete parentModule.modules[moduleKey]

    this.emit('moduleRemoved', ensurePath(path), module)

    this.flush()
  }
}

export default BaseController
