import { throwError } from './utils'
import { Primitive } from 'function-tree'

class Module {
  constructor(moduleDescription) {
    this.moduleDescription = moduleDescription
  }
  create(controller, path) {
    const stringPath = path.join('.')
    const moduleStub = {
      controller,
      path: stringPath,
      name: path.slice().pop(),
    }

    const module =
      typeof this.moduleDescription === 'function'
        ? this.moduleDescription(moduleStub)
        : Object.assign({}, this.moduleDescription)

    /* Convert arrays to actually runable signals */
    module.signals = Object.keys(module.signals || {}).reduce(
      (currentSignals, signalKey) => {
        const signal = module.signals[signalKey]
        if (
          !(
            signal &&
            (Array.isArray(signal) ||
              typeof signal === 'function' ||
              signal instanceof Primitive)
          )
        ) {
          throwError(
            `Signal with name "${signalKey}" is not correctly defined. Please check that the signal is either a sequence, an array or a function.`
          )
        }
        currentSignals[signalKey] = {
          signal: signal,
          run(payload) {
            controller.runSignal(
              path.concat(signalKey).join('.'),
              signal,
              payload
            )
          },
        }

        return currentSignals
      },
      {}
    )

    /* Instantiate submodules */
    module.modules = Object.keys(module.modules || {}).reduce(
      (registered, moduleKey) => {
        if (!module.modules[moduleKey].create) {
          throw new Error(
            `You are not using the Module factory on module "${moduleKey}"`
          )
        }

        registered[moduleKey] = module.modules[moduleKey].create(
          controller,
          path.concat(moduleKey)
        )

        return registered
      },
      {}
    )

    return module
  }
}

export default Module
