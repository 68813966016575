export default function(target, ...values) {
  function merge(context) {
    if (!context.resolve.isTag(target, 'state', 'module')) {
      throw new Error(
        'Cerebral operator.merge: You have to use the STATE or MODULE tag as first argument'
      )
    }

    context[target.type].merge(
      context.resolve.path(target),
      ...values.map((value) => {
        if (context.resolve.isTag(value)) {
          return context.resolve.value(value)
        }

        return Object.keys(value).reduce((currentValue, key) => {
          currentValue[key] = context.resolve.value(value[key])

          return currentValue
        }, {})
      })
    )
  }

  merge.displayName = `operator.merge(${String(target)}, ${values
    .map((value) => {
      return String(value)
    })
    .join(',')})`

  return merge
}
