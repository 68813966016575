import { Tag } from './tags'
import { ResolveValue } from 'function-tree'

export class Compute extends ResolveValue {
  constructor(args) {
    super()
    this.args = args
    this.value = null
  }
  getValue(context) {
    const computeGet = function(tag) {
      return tag.getValue(context)
    }
    const result = this.args.reduce(
      (details, arg, index) => {
        if (arg instanceof Tag) {
          const path = arg.getPath(context)

          if (path.indexOf('.*') > 0) {
            const value = arg.getValue(context)

            details.results.push(value ? Object.keys(value) : [])
          } else {
            details.results.push(arg.getValue(context))
          }

          return details
        } else if (arg instanceof ResolveValue) {
          details.results.push(arg.getValue(context))

          return details
        } else if (typeof arg === 'function') {
          details.results.push(
            arg(
              ...details.results.slice(details.previousFuncIndex, index),
              computeGet
            )
          )
          details.previousFuncIndex = index

          return details
        }

        details.results.push(arg)

        return details
      },
      {
        results: [],
        previousFuncIndex: 0,
      }
    )

    return result.results[result.results.length - 1]
  }
}

export default function compute(...args) {
  return new Compute(args)
}
