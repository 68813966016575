import { createTemplateTag, extractValueWithPath } from 'function-tree'

export {
  createTemplateTag,
  extractValueWithPath,
  resolveObject,
  ResolveValue,
  Tag,
} from 'function-tree'

export const string = createTemplateTag('string', (path) => {
  return path
})

export const signal = createTemplateTag('signal', (path, context) => {
  // View
  if (typeof context.signal === 'function') {
    return context.signal(path)
  }

  return context.controller.getSignal(path)
})

export const signals = createTemplateTag('signals', (path, context) => {
  // View
  if (typeof context.signal === 'function') {
    return context.signals(path)
  }

  return context.controller.getSignals(path)
})

export const state = createTemplateTag('state', (path, context) => {
  // Computed tracking and View
  if (typeof context.state === 'function') {
    return context.state(path)
  }

  return context.state.get(path)
})

export const module = createTemplateTag('module', (path, context) => {
  return context.module.get(path)
})

export const props = createTemplateTag('props', (path, context) => {
  // Computed tracking and View
  if (typeof context.props === 'function') {
    return context.props(path)
  }

  return extractValueWithPath(context.props, path)
})
