export default function(target, ...args) {
  function splice(context) {
    if (!context.resolve.isTag(target, 'state', 'module')) {
      throw new Error(
        'Cerebral operator.splice: You have to use the STATE or MODULE tag as first argument'
      )
    }

    const spliceArgs = args.map((arg) => context.resolve.value(arg))

    context[target.type].splice(context.resolve.path(target), ...spliceArgs)
  }

  splice.displayName = `operator.splice(${String(target)}, ${args
    .map((arg) => {
      return String(arg)
    })
    .join(',')})`

  return splice
}
