import { processResponse } from '../utils'

function httpPutFactory(url, body = {}, options) {
  function httpPut({ http, path, resolve }) {
    return processResponse(
      http.put(resolve.value(url), resolve.value(body), options),
      path
    )
  }

  return httpPut
}

export default httpPutFactory
