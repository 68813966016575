import { processResponse } from '../utils'

function httpGetFactory(url, query = {}, options) {
  function httpGet({ http, path, resolve }) {
    return processResponse(
      http.get(resolve.value(url), resolve.value(query), options),
      path
    )
  }

  return httpGet
}

export default httpGetFactory
