import { processResponse } from '../utils'

function httpPostFactory(url, body = {}, options) {
  function httpPost({ http, path, resolve }) {
    return processResponse(
      http.post(resolve.value(url), resolve.value(body), options),
      path
    )
  }

  return httpPost
}

export default httpPostFactory
