import Provider from '../Provider'
import { ResolveValue } from '../ResolveValue'
import { Tag } from '../Tag'

export default new Provider(
  {
    isTag(arg, ...types) {
      if (!(arg instanceof Tag)) {
        return false
      }

      if (types.length) {
        return types.reduce((isType, type) => {
          return isType || type === arg.type
        }, false)
      }

      return true
    },
    isResolveValue(arg) {
      return arg instanceof ResolveValue
    },
    value(arg, overrides = {}) {
      if (arg instanceof ResolveValue) {
        return arg.getValue(
          overrides ? Object.assign({}, this.context, overrides) : this.context
        )
      }

      return arg
    },
    path(arg) {
      if (arg instanceof Tag) {
        return arg.getPath(this.context)
      }

      throw new Error(
        'You are extracting a path from an argument that is not a Tag.'
      )
    },
  },
  {
    wrap: false,
  }
)
