import { ensurePath, cleanPath, throwError } from '../utils'
import Provider from '../Provider'

export const methods = [
  'concat',
  'get',
  'increment',
  'merge',
  'pop',
  'push',
  'set',
  'shift',
  'splice',
  'toggle',
  'unset',
  'unshift',
]

export default function StateProviderFactory(devtools) {
  let asyncTimeout = null

  return Provider(
    methods.reduce((currentState, methodKey) => {
      currentState[methodKey] = function(...args) {
        const model = this.context.controller.model
        const path = ensurePath(cleanPath(args.shift()))

        if (methodKey === 'get') {
          return model.get(path)
        }

        if (this.context.controller.flush) {
          clearTimeout(asyncTimeout)
          asyncTimeout = setTimeout(() => this.context.controller.flush())
        }

        return model[methodKey].apply(model, [path].concat(args))
      }

      return currentState
    }, {}),
    {
      wrap: devtools
        ? (context, functionDetails) => {
            let asyncTimeout = null

            return methods.reduce((currentState, methodKey) => {
              if (methodKey === 'get' || methodKey === 'compute') {
                currentState[methodKey] = (path) => {
                  return context.controller.model[methodKey](
                    ensurePath(cleanPath(path))
                  )
                }
              } else {
                const originFunc = context.controller.model[methodKey]

                currentState[methodKey] = (...args) => {
                  const argsCopy = args.slice()
                  const path = ensurePath(argsCopy.shift())

                  context.debugger.send({
                    datetime: Date.now(),
                    type: 'mutation',
                    color: '#333',
                    method: methodKey,
                    args: [path, ...argsCopy],
                  })

                  if (context.controller.flush) {
                    clearTimeout(asyncTimeout)
                    asyncTimeout = setTimeout(() => context.controller.flush())
                  }

                  try {
                    originFunc.apply(context.controller.model, [
                      path,
                      ...argsCopy,
                    ])
                  } catch (e) {
                    const signalName = context.execution.name
                    throwError(
                      `The Signal "${signalName}" with action "${
                        functionDetails.name
                      }" has an error: ${e.message}`
                    )
                  }
                }
              }

              return currentState
            }, {})
          }
        : false,
    }
  )
}
