export class ResolveValue {
  // "getValue" should receive a context to extract the value
  getValue() {
    throw new Error(
      'Extending ResolveValue requires you to add a "getValue" method'
    )
  }
}

export function extractValueWithPath(obj, path) {
  return path.split('.').reduce((currentValue, key, index) => {
    if (index > 0 && currentValue === undefined) {
      throw new Error(
        `Cannot extract value at path "${path}" ("${key}" is not defined).`
      )
    }

    return currentValue[key]
  }, obj)
}

class ResolveObject extends ResolveValue {
  constructor(cvalue) {
    super()
    this.cvalue = cvalue
  }

  getValue({ resolve }) {
    const cvalue = this.cvalue
    if (resolve.isResolveValue(cvalue)) {
      return resolve.value(cvalue)
    }

    return Object.keys(cvalue).reduce((convertedObject, key) => {
      convertedObject[key] = resolve.value(cvalue[key])

      return convertedObject
    }, {})
  }
}

export const resolveObject = (obj) => new ResolveObject(obj)
