export default function(target) {
  function toggle(context) {
    if (!context.resolve.isTag(target, 'state', 'module')) {
      throw new Error(
        'Cerebral operator.toggle: You have to use the STATE or MODULE tag as first argument'
      )
    }

    const path = context.resolve.path(target)

    context[target.type].toggle(path)
  }

  toggle.displayName = `operator.toggle(${String(target)})`

  return toggle
}
