import Controller from './Controller'
import { ensurePath, throwError, getModule } from './utils'

class UniversalController extends Controller {
  constructor(rootModule, controllerOptions) {
    super(rootModule, controllerOptions)
    this.changes = []
    this.model.state = JSON.parse(JSON.stringify(this.model.state))
    this.trackChanges = this.trackChanges.bind(this)
    this.on('flush', this.trackChanges)
    this.hasRun = false
  }
  trackChanges(changes) {
    this.changes = this.changes.concat(changes)
  }
  getChanges() {
    return this.changes.reduce((changes, change) => {
      changes[change.path.join('.')] = this.getState(change.path)

      return changes
    }, {})
  }
  getScript() {
    const state = JSON.stringify(this.getChanges())

    this.hasRun = true
    return `<script>window.CEREBRAL_STATE = ${state}</script>`
  }
  runSequence(sequence, payload) {
    let signalRun

    if (Array.isArray(sequence)) {
      signalRun = this.run('UniversalController.run', sequence, payload)
    } else if (typeof sequence === 'string') {
      const pathArray = ensurePath(sequence)
      const signalKey = pathArray.pop()
      const module = getModule(pathArray, this.module)
      const signalDefinition = module && module.signals[signalKey]

      signalRun = this.run(sequence, signalDefinition.signal, payload)
    } else {
      throwError('Sequence must be a signal-path or an array of action.')
    }

    return signalRun
  }
  setState(path, value) {
    this.model.set(ensurePath(path), value)
    this.flush(true) // Track changes.
  }
}

export default UniversalController
