/*
   If it walks like a duck and quacks like a duck...
 */
export function isPromise(result) {
  return (
    result &&
    (result instanceof Promise ||
      (typeof result.then === 'function' && typeof result.catch === 'function'))
  )
}

export function isPlainValue(value) {
  if (
    value !== null &&
    typeof value === 'object' &&
    !Array.isArray(value) &&
    value.constructor !== Object
  ) {
    return false
  }

  return true
}
