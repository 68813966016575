import { Provider } from '../'

export default function DebuggerProvider(devtools) {
  return Provider(
    {
      send(debuggerData) {
        devtools.sendExecutionData(
          debuggerData,
          this.context.execution,
          this.context.functionDetails,
          this.context.props
        )
      },
      wrapProvider(name, provider) {
        return Object.keys(provider).reduce((wrappedProvider, key) => {
          const originalFunc = provider[key]

          wrappedProvider[key] = (...args) => {
            this.context.debugger.send({
              method: `${name}.${key}`,
              args: args,
            })

            console.log(provider.context)
            return originalFunc.apply(provider, args)
          }

          return wrappedProvider
        }, {})
      },
    },
    {
      wrap: false,
    }
  )
}
