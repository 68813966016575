export default function(target) {
  function pop(context) {
    if (!context.resolve.isTag(target, 'state', 'module')) {
      throw new Error(
        'Cerebral operator.pop: You have to use the STATE or MODULE tag as first argument'
      )
    }

    context[target.type].pop(context.resolve.path(target))
  }

  pop.displayName = `operator.pop(${String(target)})`

  return pop
}
