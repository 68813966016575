import { ensurePath, throwError } from '../utils'
import Provider from '../Provider'
import { methods } from './State'

export default function ModuleProviderFactory(devtools) {
  return Provider(
    methods.reduce((currentState, methodKey) => {
      currentState[methodKey] = function(path = [], ...args) {
        path = ensurePath(path)
        const signalPath = this.context.execution.name.split('.')
        const modulePath = signalPath.splice(0, signalPath.length - 1)

        return this.context.state[methodKey](modulePath.concat(path), ...args)
      }

      return currentState
    }, {}),
    {
      wrap: devtools
        ? (context, functionDetails) => {
            return methods.reduce((currentState, methodKey) => {
              if (methodKey === 'get' || methodKey === 'compute') {
                currentState[methodKey] = (path) => {
                  path = ensurePath(path)
                  const signalPath = context.execution.name.split('.')
                  const modulePath = signalPath.splice(0, signalPath.length - 1)

                  path = modulePath.concat(path)

                  return context.state[methodKey](path)
                }
              } else {
                const originFunc = context.state[methodKey]

                currentState[methodKey] = (...args) => {
                  const argsCopy = args.slice()
                  let path = ensurePath(argsCopy.shift())
                  const signalPath = context.execution.name.split('.')
                  const modulePath = signalPath.splice(0, signalPath.length - 1)

                  path = modulePath.concat(path)

                  context.debugger.send({
                    datetime: Date.now(),
                    type: 'mutation',
                    color: '#333',
                    method: `module.${methodKey}`,
                    args: [path, ...argsCopy],
                  })

                  try {
                    originFunc.apply(context.state, [path, ...argsCopy])
                  } catch (e) {
                    const signalName = context.execution.name
                    throwError(
                      `The Signal "${signalName}" with action "${
                        functionDetails.name
                      }" has an error: ${e.message}`
                    )
                  }
                }
              }

              return currentState
            }, {})
          }
        : false,
    }
  )
}
