export default function(target) {
  function unset(context) {
    if (!context.resolve.isTag(target, 'state', 'module')) {
      throw new Error(
        'Cerebral operator.unset: You have to use the STATE or MODULE tag as first argument'
      )
    }

    context[target.type].unset(context.resolve.path(target))
  }

  unset.displayName = `operator.unset(${String(target)})`

  return unset
}
