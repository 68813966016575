export default function(target, value) {
  function unshift(context) {
    if (!context.resolve.isTag(target, 'state', 'module')) {
      throw new Error(
        'Cerebral operator.unshift: You have to use the STATE or MODULE tag as first argument'
      )
    }

    context[target.type].unshift(
      context.resolve.path(target),
      context.resolve.value(value)
    )
  }

  unshift.displayName = `operator.unshift(${String(target)}, ${String(value)})`

  return unshift
}
