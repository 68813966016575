import {
  isObject,
  isComplexObject,
  isSerializable,
  throwError,
  forceSerializable,
  addCerebralStateKey,
} from './utils'
import BaseModel from './BaseModel'

class Model extends BaseModel {
  constructor(controller) {
    super(controller)
    this.controller = controller
    this.devtools = controller.devtools
    this.state =
      this.devtools && this.devtools.warnStateProps
        ? addCerebralStateKey(this.initialState)
        : this.initialState

    controller.on('initialized', () => {
      this.flush()
    })
  }
  updateIn(path, cb, forceChildPathUpdates = false) {
    if (!path.length) {
      cb(this.state, this, 'state')

      return
    }

    path.reduce((currentState, key, index) => {
      if (index === path.length - 1) {
        if (!Array.isArray(currentState) && !isObject(currentState)) {
          throwError(
            `The path "${path.join('.')}" is invalid. Path: "${path
              .slice(0, path.length - 1)
              .join('.')}" is type of "${
              currentState === null ? 'null' : typeof currentState
            }"`
          )
        }

        const currentValue = currentState[key]

        cb(currentState[key], currentState, key)
        if (
          currentState[key] !== currentValue ||
          (isComplexObject(currentState[key]) && isComplexObject(currentValue))
        ) {
          this.changedPaths.push({
            path,
            forceChildPathUpdates,
          })
        }
      } else if (!currentState[key]) {
        currentState[key] = {}
      }

      return currentState[key]
    }, this.state)
  }
  verifyValue(value, path) {
    if (this.devtools && !isSerializable(value, this.devtools.allowedTypes)) {
      throwError(
        `You are passing a non serializable value into the state tree on path "${path.join(
          '.'
        )}"`
      )
    }
    if (this.devtools) {
      forceSerializable(value)
    }
    if (this.devtools && this.devtools.warnStateProps) {
      addCerebralStateKey(value)
    }
  }
  verifyValues(values, path) {
    if (this.devtools) {
      values.forEach((value) => {
        this.verifyValue(value, path)
      })
    }
  }
  emitMutationEvent(method, path, ...args) {
    this.controller.emit('mutation', {
      method,
      path,
      args,
    })
  }
  get(path = []) {
    return path.reduce((currentState, key) => {
      return currentState ? currentState[key] : undefined
    }, this.state)
  }
  set(path, value) {
    this.verifyValue(value, path)
    this.updateIn(
      path,
      (_, parent, key) => {
        parent[key] = value
      },
      true
    )
    this.emitMutationEvent('set', path, value)
  }
  toggle(path) {
    this.updateIn(path, (value, parent, key) => {
      parent[key] = !value
    })
    this.emitMutationEvent('toggle', path)
  }
  push(path, value) {
    this.verifyValue(value, path)
    this.updateIn(path, (array) => {
      array.push(value)
    })
    this.emitMutationEvent('push', path, value)
  }
  merge(path, ...values) {
    const value = Object.assign(...values)

    // If we already have an object we make it behave
    // like multiple sets, indicating a change to very key.
    // If no value it should indicate that we are setting
    // a new object
    if (this.get(path)) {
      for (let prop in value) {
        this.set(path.concat(prop), value[prop])
      }
    } else {
      this.set(path, value)
    }
    this.emitMutationEvent('merge', path, ...values)
  }
  pop(path) {
    this.updateIn(path, (array) => {
      array.pop()
    })
    this.emitMutationEvent('pop', path)
  }
  shift(path) {
    this.updateIn(path, (array) => {
      array.shift()
    })
    this.emitMutationEvent('shift', path)
  }
  unshift(path, value) {
    this.verifyValue(value, path)
    this.updateIn(path, (array) => {
      array.unshift(value)
    })
    this.emitMutationEvent('unshift', path, value)
  }
  splice(path, ...args) {
    this.verifyValues(args, path)
    this.updateIn(path, (array) => {
      array.splice(...args)
    })
    this.emitMutationEvent('splice', path, ...args)
  }
  unset(path) {
    this.updateIn(
      path,
      (_, parent, key) => {
        delete parent[key]
      },
      true
    )
    this.emitMutationEvent('unset', path)
  }
  concat(path, value) {
    this.verifyValue(value, path)
    this.updateIn(path, (array, parent, key) => {
      parent[key] = array.concat(value)
    })
    this.emitMutationEvent('concat', path, value)
  }
  increment(path, delta = 1) {
    if (!Number.isInteger(delta)) {
      throw new Error(
        'Cerebral state.increment: you must increment with integer values.'
      )
    }
    this.updateIn(path, (value, parent, key) => {
      if (!Number.isInteger(value)) {
        throw new Error(
          'Cerebral state.increment: you must increment integer values.'
        )
      }
      parent[key] = value + delta
    })
    this.emitMutationEvent('increment', path, delta)
  }
}

export default Model
