import DependencyStore from './DependencyStore'
import BaseController from './BaseController'
import Model from './Model'

/*
  The controller is where everything is attached. The devtools
  is attached directly. Also a top level module is created.
  The controller creates the function tree that will run all signals,
  based on top level providers and providers defined in modules
*/
class Controller extends BaseController {
  constructor(rootModule, options) {
    super(
      rootModule,
      Object.assign(
        {
          Model,
        },
        options
      )
    )

    this.componentDependencyStore = new DependencyStore()
    this.flush = this.flush.bind(this)

    this.on('asyncFunction', (execution, funcDetails) => {
      if (!funcDetails.isParallel) {
        this.flush()
      }
    })
    this.on('parallelStart', () => this.flush())
    this.on(
      'parallelProgress',
      (execution, currentPayload, functionsResolving) => {
        if (functionsResolving === 1) {
          this.flush()
        }
      }
    )
    this.on('end', () => this.flush())
  }
  /*
    Whenever components needs to be updated, this method
    can be called
  */
  flush(force) {
    const changes = this.model.flush()

    if (!force && !Object.keys(changes).length) {
      return
    }

    this.updateComponents(changes, force)
    this.emit('flush', changes, Boolean(force))
  }
  updateComponents(changes, force) {
    let componentsToRender = []

    if (force) {
      componentsToRender = this.componentDependencyStore.getAllUniqueEntities()
    } else {
      componentsToRender = this.componentDependencyStore.getUniqueEntities(
        changes
      )
    }

    const start = Date.now()
    componentsToRender.forEach((component) => {
      if (this.devtools) {
        this.devtools.updateComponentsMap(component)
      }
      component.onUpdate(changes, force)
    })
    const end = Date.now()

    if (this.devtools && componentsToRender.length) {
      this.devtools.sendComponentsMap(componentsToRender, changes, start, end)
    }
  }
}

export default Controller
