import ExtendableError from 'es6-error'

export default class CerebralError extends ExtendableError {
  constructor(message, details) {
    super(message)
    this.name = 'CerebralError'
    this.details = details
    this.toJSON = function() {
      return Object.getOwnPropertyNames(this).reduce((props, key) => {
        if (!['toJSON', 'execution', 'functionDetails'].includes(key)) {
          props[key] = this[key]
        }

        return props
      }, {})
    }
  }
}
