import { Sequence, Parallel } from './primitives'

export { FunctionTreeExecutionError, FunctionTreeError } from './errors'
export { Primitive } from './primitives'
export { default as createStaticTree } from './staticTree'
export { default as Path } from './Path'

export function sequence(...args) {
  return new Sequence(...args)
}

export function parallel(...args) {
  return new Parallel(...args)
}

export { FunctionTree } from './FunctionTree'
export { FunctionTree as default } from './FunctionTree'
export { default as Provider } from './Provider'

export {
  extractValueWithPath,
  resolveObject,
  ResolveValue,
} from './ResolveValue'
export { createTemplateTag, Tag } from './Tag'
