import React from 'react'
import { View, throwError } from 'cerebral'
import PropTypes from 'prop-types'

class BaseComponent extends React.Component {
  constructor(dependencies, mergeProps, props, controller, name) {
    super(props)
    if (!controller) {
      throwError(
        'Can not find controller, did you remember to use the Container component? Read more at: http://cerebraljs.com/docs/api/components.html#react'
      )
    }

    this.onUpdate = this.onUpdate.bind(this)
    this.view = new View({
      dependencies,
      mergeProps,
      props,
      controller,
      displayName: name,
      onUpdate: this.onUpdate,
    })
  }
  /*
    Register the component to the dependency store with its
    state tracker and tags state dependencies
  */
  componentWillMount() {
    this.view.mount()
  }
  /*
    We only allow forced render by change of props passed
    or Container tells it to render
  */
  shouldComponentUpdate() {
    return false
  }
  /*
    If received props differ, we need to update any
    StateTrackers and tags, cause they might be using
    props to define a state dependency
  */
  componentWillReceiveProps(nextProps) {
    const hasUpdate = this.view.onPropsUpdate(this.props, nextProps)
    if (hasUpdate) {
      this.forceUpdate()
    }
  }
  /*
    Unregister with existing state dependencies
  */
  componentWillUnmount() {
    this.view.unMount()
  }
  onUpdate(stateChanges, force) {
    this.view.updateFromState(stateChanges, this.props, force)
    this.forceUpdate()
  }
}

export default function HOC(dependencies, mergeProps, Component) {
  class CerebralComponent extends BaseComponent {
    constructor(props, context) {
      super(
        dependencies,
        mergeProps,
        props,
        context.controller,
        Component.displayName || Component.name
      )
    }
    toJSON() {
      return this.view._displayName
    }
    render() {
      return React.createElement(Component, this.view.getProps(this.props))
    }
  }
  CerebralComponent.displayName = `CerebralWrapping_${Component.displayName ||
    Component.name}`

  CerebralComponent.contextTypes = {
    controller: PropTypes.object,
  }

  return CerebralComponent
}
