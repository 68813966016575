import { ResolveValue } from './ResolveValue'

/*
  Creates tag for targetting things with a path in Cerebral
*/
export class Tag extends ResolveValue {
  constructor(type, getter, strings, values) {
    super()
    this.type = type
    this.getter = getter
    this.strings = strings
    this.values = values
  }
  /*
    Returns all tags, also nested to identify nested state dependencies
    in components
  */
  getTags() {
    return [this].concat(this.getNestedTags())
  }
  /*
    Gets the path of the tag, where nested tags are evaluated
  */
  getPath(context) {
    return this.strings.reduce((currentPath, string, idx) => {
      const valueTemplate = this.values[idx]

      if (valueTemplate instanceof ResolveValue) {
        return currentPath + string + valueTemplate.getValue(context)
      }

      return (
        currentPath +
        string +
        (valueTemplate !== undefined ? valueTemplate : '')
      )
    }, '')
  }
  getValue(context) {
    return this.getter(this.getPath(context), context)
  }
  /*
    Grab nested tags from the tags current path
  */
  getNestedTags() {
    return this.strings.reduce((currentPaths, string, idx) => {
      const valueTemplate = this.values[idx]

      if (valueTemplate instanceof Tag) {
        return currentPaths.concat(valueTemplate)
      }

      return currentPaths
    }, [])
  }
  /*
    Produces a string representation of the tag
  */
  toString() {
    return this.type + '`' + this.pathToString() + '`'
  }
  /*
    Produces a string representation of the path
  */
  pathToString() {
    return this.strings.reduce((currentPath, string, idx) => {
      const valueTemplate = this.values[idx]

      if (valueTemplate instanceof Tag) {
        return currentPath + string + '${' + valueTemplate.toString() + '}'
      }

      return (
        currentPath +
        string +
        (valueTemplate !== undefined ? valueTemplate : '')
      )
    }, '')
  }
}

export function createTemplateTag(tag, getValue) {
  return (strings, ...values) => {
    if (values.some((value) => value === undefined)) {
      throw new Error(
        'One of the values passed inside the tag interpolated to undefined. Please check.'
      )
    }
    return new Tag(tag, getValue, strings, values)
  }
}
