import { isPromise, isPlainValue } from './utils'

class Provider {
  constructor(definition, { wrap = true, ignoreDefinition = false } = {}) {
    this.definition = definition

    if (typeof definition === 'function') {
      return
    }

    if (!ignoreDefinition) {
      this.verifyDefinition(definition)
    }

    this.wrap = wrap

    this.ProviderConstructor = function(context) {
      this.context = context
    }
    this.ProviderConstructor.prototype = definition

    this.WrappedProviderConstructor = function(name, context) {
      this.context = context
      this.providerName = name
    }
    this.WrappedProviderConstructor.prototype = Object.keys(
      ignoreDefinition ? {} : definition
    ).reduce((wrappedProvider, key) => {
      const originalFunc = definition[key]

      wrappedProvider[key] = function(...args) {
        const providerResult = originalFunc.apply(this, args)

        if (isPromise(providerResult)) {
          return providerResult
            .then((result) => {
              this.context.debugger.send({
                type: 'provider',
                datetime: Date.now(),
                method: `${this.providerName}.${key}`,
                args: args,
                isPromise: true,
                isRejected: false,
                returnValue: isPlainValue(result)
                  ? result
                  : '[CAN_NOT_SERIALIZE]',
              })

              return result
            })
            .catch((error) => {
              this.context.debugger.send({
                method: `${this.providerName}.${key}`,
                args: args,
                isPromise: true,
                isRejected: true,
              })

              throw error
            })
        }

        this.context.debugger.send({
          type: 'provider',
          datetime: Date.now(),
          method: `${this.providerName}.${key}`,
          args: args,
          returnValue: isPlainValue(providerResult)
            ? providerResult
            : '[CAN_NOT_SERIALIZE]',
        })

        return providerResult
      }

      return wrappedProvider
    }, {})
  }
  verifyDefinition(definition) {
    if (this.ignoreDefinition) {
      return
    }

    if (typeof definition !== 'object' || definition === null) {
      throw new Error('The definition passed as Provider is not valid')
    }

    Object.keys(definition).forEach((key) => {
      if (typeof definition[key] !== 'function') {
        throw new Error(
          `The property ${key} passed to Provider is not a method`
        )
      }
    })
  }
  get(context) {
    if (typeof this.definition === 'function') {
      return this.definition(context)
    }

    return new this.ProviderConstructor(context)
  }
  getWrapped(name, context) {
    if (typeof this.definition === 'function') {
      return this.definition(context)
    }

    return new this.WrappedProviderConstructor(name, context)
  }
}

export default Provider
