class Path {
  constructor(path, payload) {
    this.path = path
    this.payload = payload
  }
  toJSON() {
    return {
      path: this.path,
      payload: this.payload,
      _functionTreePath: true,
    }
  }
}

export default Path
