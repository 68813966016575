import { processResponse } from '../utils'

function httpPatchFactory(url, body = {}, options) {
  function httpPatch({ http, path, resolve }) {
    return processResponse(
      http.patch(resolve.value(url), resolve.value(body), options),
      path
    )
  }

  return httpPatch
}

export default httpPatchFactory
