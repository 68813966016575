export default function(target) {
  function shift(context) {
    if (!context.resolve.isTag(target, 'state', 'module')) {
      throw new Error(
        'Cerebral operator.shift: You have to use the STATE or MODULE tag as first argument'
      )
    }

    context[target.type].shift(context.resolve.path(target))
  }

  shift.displayName = `operator.shift(${String(target)})`

  return shift
}
