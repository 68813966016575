export default function(target, value) {
  function concat(context) {
    if (!context.resolve.isTag(target, 'state', 'module')) {
      throw new Error(
        'Cerebral operator.concat: You have to use the STATE or MODULE tag as first argument'
      )
    }

    context[target.type].concat(
      context.resolve.path(target),
      context.resolve.value(value)
    )
  }

  concat.displayName = `operator.concat(${String(target)}, ${String(value)})`

  return concat
}
